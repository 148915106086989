import React, { useState } from 'react';
import './style.css';
import Autocomplete from './Autocomplete';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from './TranslationContext';
import LocationPopup from './LocationPopup'; // Import the LocationPopup component

const Header_home = () => {
    const [isLocationPopupOpen, setIsLocationPopupOpen] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState('Coimbatore');
    const navigate = useNavigate();
    const { translate, setLanguage } = useTranslation();

    const handleLocationClick = () => {
        setIsLocationPopupOpen(true);
    };

    const handleLocationSelect = (location) => {
        setSelectedLocation(location);
        setIsLocationPopupOpen(false);
    };

    const handleDivClick = () => {
        navigate('/Home');
    };

    const handlelogout = () => {
        localStorage.clear();
        navigate('/Login');
    };

    const handleprofile = () => {
        navigate('/Myorders');
    };

    const handleLanguageChange = (event) => {
        setLanguage(event.target.value);
    };

    return (
        <div className="header">
            <div className="row">
                <div className='col-md-3'>
                    <img className="" alt="Logo" title="Logo" src={require('./img/LogoT.png')} onClick={handleDivClick} />
                </div>
                <div className='col-md-2'>
                    <p className="trackorder">Movies</p>
                </div>
                <div className='col-md-2'>
                    <p className="trackorder">Events</p>
                </div>
                <div className='col-md-2'>
                    <p className="trackorder">Sports</p>
                </div>
                <div className='col-md-1' >
                    <select className='country_name' onClick={handleLocationClick} value={selectedLocation} readOnly>
                        <option>{selectedLocation}</option>
                    </select>
                </div>
                <div className='col-md-1'>
                    <img className="vector1" alt="Profile" title="Profile" onClick={handleprofile} src={require('./img/Vector1.png')} />
                </div>
                <div className='col-md-1'>
                    <img className="logout" alt="Logout" title="Logout" src={require('./img/logout.png')} onClick={handlelogout} />
                </div>
            </div>
            <LocationPopup
                isOpen={isLocationPopupOpen}
                onClose={() => setIsLocationPopupOpen(false)}
                onSelectLocation={handleLocationSelect}
            />
        </div>
    );
};

export default Header_home;
