import React, { useState, useEffect } from 'react';
import './style.css';
import Header_home from './Header_home';
import Footer from './Footer';
import Menu from './Menu';
import axios from "axios";
import Prductslide from './Prductslide';
import Prductslide3 from './Prductslide3';

import Catslide from './Catslide';
import Bannerslideshow from './Bannerslideshow';
import Cookies from 'js-cookie';
import "./Prductslide.css";
import Product_rating from './Product_rating';
import { useTranslation } from './TranslationContext';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const [state, setState] = useState({
    api_url: localStorage.getItem('api_url'),
    data: [],
    coo_product_id: localStorage.getItem('coo_product_id'),
    coo_product_name: localStorage.getItem('coo_product_name'),
    coo_product_image: localStorage.getItem('coo_product_image'),
    coo_price: localStorage.getItem('coo_price'),
    coo_quantity: localStorage.getItem('coo_quantity'),
    prescription: 'no',
    search: '',
    suc_cls: '',
    errors: {},
    openModal: false,
    pic: '',
    fileName: '',
    email: '',
    email1: '',
    password: ''
  });

  const { translate } = useTranslation();
  const navigate = useNavigate();

  const handleButtonClick = (e, id) => {
    e.preventDefault();
    localStorage.setItem("product_id", id);
    navigate('/Detail_page');
  };

  const toggleModal = () => {
    setState(prevState => ({
      ...prevState,
      openModal: !prevState.openModal
    }));
  };

  useEffect(() => {
    // const api_url = localStorage.getItem('api_url');
    // axios.get(`${api_url}/gettrandproduct/`).then(response => console.log(response.data));
  }, []);

  return (
    <div className="">
      <Header_home />
      {/* <Menu /> */}
      <Bannerslideshow />
      <div className='' style={{padding:"50px"}}>
        <br /><br />
        <h2 className="">Recommended Movies</h2>
        <br /><br />
        <Prductslide />
        <br /><br />
      </div>
      <div className='' style={{backgroundColor:"#E5E7F1",padding:"50px"}}>
        <h2 className="">Trending Movies</h2>
        <Prductslide />
        <br /><br />
      </div>
     
      
      <div className='' style={{padding:"50px"}}>
        <br /><br />
        <h2 className="">Explore Genres</h2>
        <br />
        {/* <Prductslide /> */}
        <br /><br />
      </div>

      <div className='' style={{padding:"50px"}}>
        <br /><br />
        <h2 className="">The Best of Live Events</h2>
        <br />
        {/* <Prductslide3 /> */}
        <br /><br />
      </div>
      <Footer />
    </div>
  );
};

export default Home;
