import React, { useState, useEffect } from "react";
import './Ordercard.css'; // Import your CSS file for styling
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import QRCode from 'react-qr-code';  // Correct import for react-qr-code


const Myorderdetails = ({ orderStatus, orderTime }) => {
  const [cards, setCards] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [searchInput, setSearchInput] = useState('');
  const [loading, setLoading] = useState(true); // Add loading state
  const [error, setError] = useState(null); // Add error state
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('tab1'); // State to track the active tab


  useEffect(() => {
    const fetchCards = async () => {
      try { 
        const user_id = localStorage.getItem('admin_id');
        const api_url = localStorage.getItem('api_url');
        const response = await axios.get(api_url + '/myOrders/' + user_id);
        let filteredCards = response.data.moviedetails;

       

        setCards(filteredCards);
        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error("Error fetching card data:", error);
        setError(error); // Set error state
        setLoading(false); // Set loading to false even if there's an error
      }
    };

    fetchCards();
  }, [orderStatus, orderTime, searchInput]); // Include orderStatus, orderTime, and searchInput in the dependency array

 
  const handleTabClick = (tab) => {
    setActiveTab(tab); // Update the active tab when a tab is clicked
  };

  return (
    <div>
      <p className='my_or1'><b>Your Orders</b></p>
      <p></p>
      <hr></hr>
      <div className="tabs-container">
      <div className="tabs">
        <div
          className={activeTab === 'tab1' ? 'tab active' : 'tab'}
          onClick={() => handleTabClick('tab1')}
        >
          Recent Booking
        </div>
        <div
          className={activeTab === 'tab2' ? 'tab active' : 'tab'}
          onClick={() => handleTabClick('tab2')}
        >
         Pre Booking
        </div>
      </div>
      <div className="tab-content1">
        {activeTab === 'tab1' && (
            <div className="movie-list">
              {loading ? (
                <p>Loading...</p>
              ) : error ? (
                <p>Error loading data</p>
              ) : (
                cards.map((movie) => (
                  <div key={movie.cart_id} className="movie-card">
                    <div className="movie-image">
                      <img src={movie.movie_image} alt={movie.movie_name} />
                    </div>
                    <div className="movie-details">
                      <h3>{movie.movie_name}</h3>
                      <p className="book_t">{movie.theatre} | {movie.screen_n}</p>
                      <p className="book_t">{movie.lang}</p>
                      <p className="book_t">{movie.timeslot}| {movie.date}</p>
                      <p className="book_t"><b>Quantity:</b> {movie.quantity}</p>
                      <p className="book_t"><b>Seats:</b> {movie.seats}</p>
                      <p className="book_t">ticket Price  <span style={{marginLeft:"160px"}}>{movie.price}</span></p> 
                      <hr></hr>
                      <p style={{textAlign:"left"}}><b>Total Amount </b><span style={{marginLeft:"122px"}}>{movie.price}</span></p>
                    </div>

                    <div>
                              <div style={{ textAlign: 'center', marginTop: '20px', marginLeft:'200px' }}>
                              <h5>BOOKING ID </h5>
                              <br></br>
                              <div>
                              <QRCode value={movie.transaction_id} size={256} />
                              </div>
                              </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          )}
          {activeTab === 'tab2' && (
            <div> <p style={{textAlign:"left"}} >No Booking Found</p></div>
          )}
      </div>
    </div>
      <br></br>
      <p> </p>
    </div>
  );
};

export default Myorderdetails;
